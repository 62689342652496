.b1kau7cb{fill:none;stroke:var(--oc-gray-8);}
.m13y1p2n{stroke:var(--oc-gray-8);}
.m1irbtau{fill:var(--oc-gray-8);}
.msj3zhm{fill:var(--oc-white);stroke-width:2;stroke:var(--oc-gray-8);}
.mfklenw{text-anchor:middle;alignment-baseline:central;fill:var(--oc-white);font-weight:700;}
.mtu5si3{text-anchor:middle;alignment-baseline:central;fill:var(--oc-gray-8);font-weight:700;}
.s1643xy5{text-anchor:middle;alignment-baseline:central;fill:var(--oc-gray-5);}
.lj260p0{font-family:var(--code);text-anchor:middle;}
.s1sljdrf{fill:none;stroke-width:2;stroke:var(--oc-gray-5);shape-rendering:geometricprecision;}

.w1suuh4v{stroke-dasharray:4 0 2;}
.s4xjrrr{stroke:var(--highlight);stroke-width:4;}
.mrh8lcw{fill:var(--oc-gray-8);stroke:none;}
.m11zx6nh{fill:var(--oc-white);stroke:var(--oc-gray-8);stroke-width:2;stroke-dasharray:0;}
.s7b58k3{stroke:var(--highlight);stroke-width:3;}
