.r1iobd05{border-color:var(--oc-red-6) !important;background-color:var(--oc-red-1);}
.p5kj9aw{border-color:var(--oc-pink-6) !important;background-color:var(--oc-pink-1);}
.gwvo0f3{border-color:var(--oc-grape-6) !important;background-color:var(--oc-grape-1);}
.v13ok10a{border-color:var(--oc-violet-6) !important;background-color:var(--oc-violet-1);}
.id1blva{border-color:var(--oc-indigo-6) !important;background-color:var(--oc-indigo-1);}
.b1c36n0j{border-color:var(--oc-blue-6) !important;background-color:var(--oc-blue-1);}
.c1wlo3s9{border-color:var(--oc-cyan-1) !important;background-color:var(--oc-cyan-1);}
.t1ybuees{border-color:var(--oc-teal-6) !important;background-color:var(--oc-teal-1);}
.g1yzmabm{border-color:var(--oc-green-6) !important;background-color:var(--oc-green-1);}
.lotei1j{border-color:var(--oc-lime-6) !important;background-color:var(--oc-lime-1);}
.o1sd8d6i{border-color:var(--oc-orange-6) !important;background-color:var(--oc-orange-1);}
.s2twnca{box-shadow:0 0 2px 2px var(--theme-8);background-color:var(--theme-1);z-index:2;}
