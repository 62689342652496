@import "../../../node_modules/open-color/open-color.css";
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;600;700&family=Karla:wght@400;600;700&display=swap");

:root {
  --tk-brown-0: #ffedd8;
  --tk-brown-1: #f3d5b5;
  --tk-brown-2: #e7bc91;
  --tk-brown-3: #d4a276;
  --tk-brown-4: #bc8a5f;
  --tk-brown-5: #a47148;
  --tk-brown-6: #8b5e34;
  --tk-brown-7: #6f4518;
  --tk-brown-8: #603808;
  --tk-brown-9: #583101;

  --body: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  --code: "Inconsolata", monospace;
  --chart-color: var(--oc-gray-8);
  --bg: rgba(255, 255, 255, 0.9);
  font-family: "open-arrow", var(--body);
}
strong {
  font-weight: 600;
}
