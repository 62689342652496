.w1usarfo{width:-webkit-min-content;width:-moz-min-content;width:min-content;}
.n12w54wz{font-family:var(--code);font-weight:700;font-size:var(--chart-font-size);}
.clvf5tv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;}
.c1fa8pas{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}
.r16ckhdb{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:default;width:calc(var(--chart-grid-size) * 2);}.r16ckhdb:first-child{padding-left:4px;}.r16ckhdb:last-child{padding-right:4px;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}
.c1g364tz{margin-right:-2px;border:2px solid var(--oc-gray-8);}
.s1u8d1nc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}
.s1upd4w5{box-shadow:0 0 2px 2px var(--theme-8);background-color:var(--theme-1);z-index:2;}
.cyk69p7{cursor:pointer;}
.shwhxle{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;-webkit-box-pack:space-around;-webkit-justify-content:space-around;-ms-flex-pack:space-around;justify-content:space-around;width:100%;cursor:default;}
.s1w9n7hr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}
.erbyb0j{padding:8px calc(var(--chart-grid-size) * 2) 16px;min-width:400px;}
.a1uym69{font-weight:bold;}
.svydht1{padding-top:2px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;padding-right:calc(var(--chart-grid-size) * 2);padding-left:calc(var(--chart-grid-size) * 2);}.svydht1 div{width:var(--chart-grid-size);text-align:center;}
.f3aa8af{border-bottom-style:solid;border-bottom-width:var(--chart-stroke-width);box-sizing:content-box;height:calc(var(--chart-grid-size) - var(--chart-stroke-width));}
